<template>
  <div>
    <b-card style="width:fit-content">
      <b-col cols="12">
        <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" variant="none" to="/marketplace/manage" v-b-tooltip.hover
          title="Go Back to the Script List">
          <feather-icon icon="ArrowLeftCircleIcon" size="25" class="d-inline text-warning" />
        </b-button>
        <h3 class="d-inline">Update the script : {{ name }}</h3>
      </b-col>
    </b-card>
    <b-card id="checkboxesCard">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-md-4 col-sm-6">
            <b-form-checkbox v-model="updateImage" switch>
              <span v-if="updateImage">Keep the old image</span>
              <span v-else>Update the Header Image</span>
            </b-form-checkbox>
          </div>
          <div class="col-md-4 col-sm-6">
            <b-form-checkbox v-model="updateScreens" switch>
              <span v-if="updateScreens">Keep the old screenshots</span>
              <span v-else>Update the Screenshots</span>
            </b-form-checkbox>
          </div>
          <div class="col-md-3 ">
            <b-form-checkbox v-model="updateZip" switch>
              <span v-if="updateZip">Keep the old Zip File</span>
              <span v-else>Update the ZIP File</span>
            </b-form-checkbox>
          </div>
        </div>
      </div>

    </b-card>
    <b-card>
      <form @submit.prevent="updateForm">
        <img :src="image_url" alt="image" sizes="(max-width: 500px) 200px" v-show="!updateZip" loading="lazy" width="100"
          height="100" />
        <b-form-group label="Name">
          <b-form-input v-model="name" required></b-form-input>
        </b-form-group>

        <b-form-group label="Image URL" v-show="updateImage">
          <b-form-file v-model="new_image_url" ref="imageInput"></b-form-file>
        </b-form-group>
        <b-form-group label="Description">
          <b-form-textarea v-model="description" rows="4" required></b-form-textarea>
        </b-form-group>
        <b-form-group label="Screenshoots" v-show="updateScreens">
          <b-form-file v-model="new_screens" multiple accept=".png,.jpg,.jpeg"></b-form-file>
        </b-form-group>
        <b-form-group label="ZIP File" v-show="updateZip">
          <b-form-file v-model="new_zip_urls" accept=".rar,.zip"></b-form-file>
        </b-form-group>
        <div class="col-md-12 mb-2">
          <label for="tags-basic">Type a new tag and press enter</label>
          <b-form-tags input-id="tags-basic" v-model="tags"></b-form-tags>
          <p class="mt-2" v-if="tags.length > 0"> {{ tags }}</p>
        </div>
        <b-button type="submit" variant="success">Submit</b-button>
      </form>
    </b-card>
  </div>
</template>

<script>
import {
  BContainer,
  BTable,
  BAvatar,
  BImg,
  BBadge,
  BCard,
  BRow,
  BCol,
  VBTooltip,
  BButtonGroup,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BModal,
  VBModal,
  BFormFile,
  BFormCheckbox,
  BFormTags,
  BFormTextarea,
  BCardText,
} from "bootstrap-vue";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import router from "@/router";

export default {
  components: {
    BTable,
    BAvatar,
    'b-tooltip': VBTooltip,
    BCard,
    BImg,
    BButtonGroup,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormFile,
    BPagination,
    BInputGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroupAppend,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BModal,
    VBModal,
    BCardText,
    BFormTextarea,
    BFormTags,
    BContainer,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      id: null,
      name: null,
      image_url: null,
      new_image_url: null,
      new_screens: [],
      screens: [],
      description: null,
      zip_urls: null,
      tags: [],
      new_zip_urls: null,
      imagePath: require('@/assets/images/icons/zip.png'),
      user_token: null,
      updateImage: false,
      updateScreens: false,
      updateZip: false,
    }
  },
  created() {
    this.id = this.$route.params.id;
    this.user_token = JSON.parse(localStorage.getItem("accessToken"));
    this.getDetailScript();
  },
  methods: {
    toggleImageInput() {
      this.updateImage = !this.updateImage
    },
    toggleScreensInput() {
      this.updateScreens = !this.updateScreens
    },
    toogleZipInput() {
      this.updateZip = !this.updateZip
    },
    getDetailScript() {

      axios.post('/get-mrktp-scripts-by-id', {
        id: this.id
      }).then((response) => {
        this.name = response.data.script.name;
        this.image_url = response.data.script.image_url;
        this.screens = response.data.script.screens;
        var cleanedData = this.screens.replace(/\\\//g, '/');
        this.screens = JSON.parse(cleanedData);
        this.description = response.data.script.description;
        this.zip_urls = response.data.script.zip_urls;
        this.tags = response.data.tags;
      })
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    async updateForm() {
      try {
        const formData = new FormData();
        formData.append('id', this.id);
        formData.append('user_token', this.user_token);
        formData.append('name', this.name);
        formData.append('tags[]', this.tags);
        formData.append('description', this.description);
        if (this.new_zip_urls !== null) {
          formData.append('zip_urls', this.new_zip_urls);
        }

        if (this.new_screens !== null) {
          if (this.new_screens.length > 0) {
            for (let i = 0; i < this.new_screens.length; i++) {
              formData.append('screens[]', this.new_screens[i]);
            }
          }
        }

        if (this.new_image_url !== null) {
          formData.append('image_url', this.new_image_url);
        }

        const response = await axios.post('/update-mrktp-scripts', formData);
        if (response.data.status === 'success') {
          this.showToast(
            "success",
            "SaveIcon",
            "Success",
            response.data.message
          );
          router.push({ name: "adminMarketplace" })
        } else {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            response.data.message
          );
        }
      } catch (error) {
        this.showToast(
          "danger",
          "AlertTriangleIcon",
          "Error",

        );
      }
    }
  }

}
</script>

<style scoped>
div.app-content.content>div.content-wrapper>div>div>div:nth-child(1)>div {
  padding-block: 3px !important;
  padding-left: 3px !important;
}
</style>